import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../Store/ActiveStoreContext'
import AuthContext from '../../Store/auth-context'
import styles from './EachDrive.module.css'
import EachStudentInterviewsData from './EachStudentInterviewsData'

const EachDrive = () => {
    const { driveIdentifier } = useParams()
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();
    const nav = useNavigate()
    const [studentsData, setStudentsData] = useState([])
    const [openInterviewData, setOpenInterviewData] = useState(false)
    const [selectedData, setSelectedData] = useState(null)

    // Ref to store the previous storeIdentifier
    const prevStoreIdentifierRef = useRef(activeStore?.storeIdentifier);

    useEffect(() => {
        // Only navigate if the storeIdentifier has changed
        if (prevStoreIdentifierRef.current !== activeStore?.storeIdentifier) {
            nav(`/drives`);
        }
        // Update ref with the latest storeIdentifier
        prevStoreIdentifierRef.current = activeStore?.storeIdentifier;
    }, [activeStore?.storeIdentifier, nav]);

    const getStudentsForPlacementDrive = async () => {
        const getStudentsForPlacementDriveResponse = await fetch(BASE_URL_API + "/getStudentsForPlacementDrive",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    driveIdentifier: driveIdentifier
                })
            });

        if (!getStudentsForPlacementDriveResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getStudentsForPlacementDriveRespo = await getStudentsForPlacementDriveResponse.json()
            if (getStudentsForPlacementDriveRespo.status === "success") {
                setStudentsData(getStudentsForPlacementDriveRespo?.response)
            } else {
                if (getStudentsForPlacementDriveRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setStudentsData([])
                }
            }
            console.log(getStudentsForPlacementDriveRespo)
        }
    }

    useEffect(() => {
        getStudentsForPlacementDrive()
    }, [driveIdentifier])

    const viewInterviewDataHandler = (k) => {
        setOpenInterviewData(true)
        setSelectedData(k)
    }

    const closeInterviewDataHandler = (k) => {
        setOpenInterviewData(false)
        setSelectedData(null)
    }







    return (
        <div className={styles.mainWrapper}>
            {openInterviewData && <EachStudentInterviewsData isOpen={openInterviewData} data={selectedData} onClose={closeInterviewDataHandler} />}
            <div className={styles.mainContainer}>
                <div className={styles.upperWrapper}>
                    <div className={styles.eachTile}>

                        {driveIdentifier}
                    </div>
                    <div className={styles.multipleTileWrapper}>
                        <div className={styles.eachRecTile} >
                            <div className={styles.ertValue}>
                                1
                            </div>
                            <div className={styles.ertTitle}>
                                Recruiters
                            </div>
                        </div>
                        <div className={styles.eachRecTile}>
                            <div className={styles.ertValue}>
                                {studentsData?.length}
                            </div>
                            <div className={styles.ertTitle}>
                                Students
                            </div>
                        </div>
                    </div>
                    <div>
                        <button onClick={() => getStudentsForPlacementDrive()}>getStudentsForPlacementDrive</button>
                    </div>
                </div>
                <div className={styles.dataWrapper}>
                    <div className={styles.tableHeader} >
                        <div className={styles.stylistName}>
                            Stylist Name
                        </div>
                        <div className={styles.contactNumber}>
                            Phone Number
                        </div>
                        <div className={styles.currentLocation}>
                            Current Location
                        </div>
                        <div className={styles.action}>
                            Action
                        </div>
                    </div>
                    <div className={styles.dataTable}>
                        {studentsData?.map((item, index) => (
                            <div key={index} className={styles.eachStudent}>
                                <div className={styles.stylistName}>
                                    {item?.stylist_name}
                                </div>
                                <div className={styles.contactNumber}>
                                    {item?.contact_number}
                                </div>
                                <div className={styles.currentLocation}>
                                    {item?.currentLocation}
                                </div>
                                <div className={styles.action}>
                                    <div className={styles.applied}>{JSON.parse(item?.applicationData)?.length || 0}</div>
                                    <button onClick={() => viewInterviewDataHandler(item?.applicationData)} >View</button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachDrive