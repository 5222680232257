import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { BASE_URL_API } from '../../References/URLs'
import ActiveStoreContext from '../../Store/ActiveStoreContext'
import AuthContext from '../../Store/auth-context'
import styles from './AllDrives.module.css'

const AllDrives = () => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const activeStore = (() => { try { return (asCtx?.activeStore && asCtx.activeStore.trim() !== "") ? JSON.parse(asCtx.activeStore) : null; } catch { return null; } })();

    const [drivesData, setDrivesData] = useState([])

    const getAllPlacementDrives = async () => {
        const getAllPlacementDrivesResponse = await fetch(BASE_URL_API + "/getAllPlacementDrives",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: activeStore?.storeIdentifier
                })
            });

        if (!getAllPlacementDrivesResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getAllPlacementDrivesRespo = await getAllPlacementDrivesResponse.json()
            if (getAllPlacementDrivesRespo.status === "success") {
                setDrivesData(getAllPlacementDrivesRespo?.response)
            } else {
                if (getAllPlacementDrivesRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setDrivesData([])
                }
            }
            console.log(getAllPlacementDrivesRespo)
        }
    }

    useEffect(() => {
        getAllPlacementDrives()
    }, [activeStore?.storeIdentifier])




    if (activeStore === null) {
        return (
            <div>
                Please select a franchise!
            </div>
        )
    } else {
        return (
            <div>
                <div>
                    <div>
                        {activeStore?.storeName}
                    </div>
                    <div>
                        {activeStore?.storeAddress}
                    </div>
                </div>
                <div>
                    <div>

                    </div>
                    <div>
                        {drivesData?.map((item, index) => (
                            <div key={index} className={styles.eachDriveWrapper} >
                                <div className={styles.driveStartingDate}>
                                    {item?.driveStartingDate}
                                </div>
                                <div className={styles.driveType}>
                                    {item?.driveType}
                                </div>
                                <div className={styles.action}>
                                    <Link to={`/drive/${item?.driveIdentifier}`} >Go To Drive</Link>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        )
    }
}

export default AllDrives