import React, { useState } from 'react'
import { IoMdArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'
import { sanitizeEmail } from '../../Helper/jarvis'
import styles from './ForgotPassword.module.css'

const ForgotPassword = () => {
    const [username, setUsername] = useState('')
    const [sanitizedEmail, setSanitizedEmail] = useState({ isValid: false, sanitizedEmail: '', message: '' })
    const [usernameValidated, setUsernameValidated] = useState(false)

    const usernameChangeHandler = (input) => {
        // Update sanitized email and validation status on every keystroke
        const result = sanitizeEmail(input)
        setSanitizedEmail(result)
        setUsername(result.sanitizedEmail)
        setUsernameValidated(false)
    }

    const validateUsernameHandler = () => {
        // Additional validation if necessary, e.g., checking if the email is valid
        if (sanitizedEmail.isValid) {
            setUsernameValidated(true)
        } else {
            setUsernameValidated(false)
        }
    }

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <Link to="/">
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/fast-track-assets/fastTrack-white.png" alt="Stylelink" />
                    </Link>
                </div>
                <div className={styles.bodyWrapper}>
                    <div>
                        <h2>Forgot Password</h2>
                    </div>
                    <div className={styles.formWrapper}>
                        <div className={styles.inputWrapper}>
                            <div className={styles.messageWrapper}>
                                <div className={sanitizedEmail.isValid ? styles.validMessageTxt : styles.invalidMessageTxt}>{sanitizedEmail.isValid ? 'E-mail Validated' : 'Invalid email'}</div>
                                <div className={sanitizedEmail.isValid ? styles.validMessageTxt : styles.invalidMessageTxt}>{sanitizedEmail.message}</div>
                            </div>
                            <div className={styles.usernameWrapper}>
                                <div className={styles.usernameContainer}>
                                    <input
                                        type="text"
                                        autoFocus={true}
                                        placeholder="Please enter your e-mail"
                                        onChange={(e) => usernameChangeHandler(e.target.value)}
                                        value={username}
                                    />
                                </div>
                                <button className={styles.nextIcon} onClick={() => validateUsernameHandler()}>
                                    <IoMdArrowForward size={24} color="white" />
                                </button>
                            </div>
                        </div>

                        <div className={styles.forgotPassword}>
                            <Link to="/login">Back to Login</Link>
                        </div>

                    </div>

                </div>
                <div className={styles.footerWrapper}>
                    <div className={styles.copyrightTxt}>
                        Copyright © 2024 Stylelink. All rights reserved.
                    </div>
                    <div className={styles.link}>
                        <Link to={`/privacy-policy`}>
                            Privacy Policy
                        </Link>
                        <Link to={`/terms-of-use`}>
                            Terms of Use
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword
