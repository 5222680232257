import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from './Login.module.css'

import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { IoMdArrowForward } from "react-icons/io";
import { BASE_URL_API } from '../../References/URLs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthContext from '../../Store/auth-context';
import { BsThreeDots } from 'react-icons/bs';



const Login = () => {
    const authCtx = useContext(AuthContext)
    const [shouldRemember, setShouldRemember] = useState(false)
    const [username, setUsername] = useState('')
    const [usernameValidated, setUsernameValidated] = useState(false)
    const [password, setPassword] = useState('')
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [responseMessage, setResponseMessage] = useState('')

    const usernameChangeHandler = (k) => {
        setUsername(k)
        setUsernameValidated(false)
        setIsAuthenticating(false)
    }
    const validateUsernameHandler = () => {
        setUsernameValidated(true)
    }
    const passwordChangeHandler = (k) => {
        setPassword(k)
        setIsAuthenticating(false)
    }

    const auth = async () => {
        if (username !== '' && password !== '') {
            setIsAuthenticating(true)
            setResponseMessage('Please wait...')
            const loginHandlerResponse = await fetch(BASE_URL_API + `/auth`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        username: username,
                        password: password,
                        remember : shouldRemember
                    })
                });

            if (!loginHandlerResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const loginHandlerRespo = await loginHandlerResponse.json();
                if (loginHandlerRespo?.status === 'success') {
                    authCtx.login(loginHandlerRespo?.response?.token, loginHandlerRespo?.response?.authIdentifier, JSON.stringify(loginHandlerRespo?.response?.loginData));
                    console.log(loginHandlerRespo)
                } else {
                    setResponseMessage('Invalid username or password!')
                    console.log(loginHandlerRespo);
                }
            }
            setIsAuthenticating(false)
        } else {
            alert(`Please enter valid details username : ${username} password : ${password}`);
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Ctrl key or Command key and Enter key are pressed simultaneously
            if (event.key === "Enter") {
                event.preventDefault();  // Prevent default behavior like form submission
                if(!usernameValidated) {
                    validateUsernameHandler()
                } else {
                    auth()
                }
            }
        };
    
        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);
    
        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [usernameValidated]);  // Ensure all dependencies are properly listed
    




    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.headerWrapper}>
                    <Link to="/">
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/fast-track-assets/fastTrack-white.png" />
                    </Link>
                </div>
                <div className={styles.bodyWrapper}>
                    <div>
                        <h2>Sign in to Fast Track</h2>
                    </div>
                    <div className={styles.formWrapper}>
                        <div className={styles.inputWrapper}>
                            <div className={usernameValidated === true ? styles.usernameWrapperValidated : styles.usernameWrapper}>
                                <div className={styles.usernameContainer}>
                                    <input type="text" autoFocus={true} placeholder="Please enter your e-mail" onChange={(e) => usernameChangeHandler(e.target.value)} />
                                </div>
                                {usernameValidated === false &&
                                    <button className={styles.nextIcon} onClick={() => validateUsernameHandler()}>
                                        <IoMdArrowForward size={24} color="white" />
                                    </button>
                                }
                            </div>
                            {usernameValidated === true &&
                                <div className={styles.passwordWrapper}>
                                    <div className={styles.passwordContainer}>
                                        <input autoFocus={true} type="password" placeholder="Please enter your password" onChange={(e) => passwordChangeHandler(e.target.value)} />
                                    </div>
                                    {isAuthenticating===false ?
                                    <button className={styles.nextIcon} onClick={() => auth()}>
                                        <IoMdArrowForward size={24} color="white" />
                                    </button>
                                    :
                                    <button className={styles.nextIcon} >
                                        <BsThreeDots size={24} color="white" />
                                    </button>
                                    }
                                </div>
                            }
                        </div>

                        <div className={styles.rememberMeWrapper}
                            onClick={() => setShouldRemember(!shouldRemember)}
                        >
                            <div className={styles.checkIconBox}>
                                {shouldRemember === true ?
                                    <MdCheckBox size={24} />
                                    :
                                    <MdCheckBoxOutlineBlank size={24} />
                                }
                            </div>
                            <div className={styles.rememberMeTxt}>
                                Remember Me
                            </div>
                        </div>

                        <div className={styles.forgotPassword}>
                            <Link to="/forgot-password">Forgot Password</Link>
                        </div>

                    </div>

                </div>
                <div className={styles.footerWrapper}>
                    <div className={styles.copyrightTxt}>
                        Copyright © 2024 Stylelink. All rights reserved.
                    </div>
                    <div className={styles.link}>
                        <Link to={`/privacy-policy`} >
                            Privacy Policy
                        </Link>
                        <Link to={`/terms-of-use`}>
                            Terms of Use
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login