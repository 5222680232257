import React, { useEffect, useRef } from 'react';
import styles from './TilesBricksUI.module.css';

const profile = [
    { "categoryId": "1", "label": "Beautician" },
    { "categoryId": "15", "label": "Beauty Advisor / Consultant" },
    { "categoryId": "13", "label": "Beauty Trainer" },
    { "categoryId": "22", "label": "Eye-lash Extension Expert" },
    { "categoryId": "19", "label": "Eyelash Trainer" },
    { "categoryId": "20", "label": "Female Hairdresser / Hairstylist" },
    { "categoryId": "17", "label": "Gents Hairdresser / Hairstylist" },
    { "categoryId": "14", "label": "Hair Trainer" },
    { "categoryId": "9", "label": "Helper" },
    { "categoryId": "12", "label": "Make-up Trainer" },
    { "categoryId": "2", "label": "Makeup Artist" },
    { "categoryId": "11", "label": "Manager" },
    { "categoryId": "4", "label": "Manicurist" },
    { "categoryId": "16", "label": "Massage Therapist" },
    { "categoryId": "21", "label": "Mehandi Artist" },
    { "categoryId": "6", "label": "Nail Art / Technician" },
    { "categoryId": "18", "label": "Nail Art Trainer" },
    { "categoryId": "5", "label": "Pedicurist" },
    { "categoryId": "10", "label": "Receptionist" },
    { "categoryId": "3", "label": "Unisex Hairdresser / Hairstylist" }
];

const generateRowPattern = (index) => {
    const patterns = [
        ['centered'],  // First row centered
        ['half-left', 'half-right'],
        ['half-left', 'full', 'half-right'],
        ['full-left', 'full-right'],
    ];

    return patterns[index % patterns.length];
};

const TilesBricksUI = () => {
    const tilesRef = useRef([]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add(styles.appear);   

                    } else {
                        entry.target.classList.remove(styles.appear);
                    }
                });
            },
            { threshold: 0.1 }
        );

        tilesRef.current.forEach((tile) => {
            if (tile) observer.observe(tile);
        });

        return () => observer.disconnect();
    }, []);

    let profileIndex = 0;
    let stopRendering = false;


    return (
        <div className={styles.container}>
        {Array.from({ length: 10 }).map((_, rowIndex) => {
            if (stopRendering) return null;

            const row = generateRowPattern(rowIndex);

            return (
                <div key={rowIndex} className={styles.row}>
                    {row.map((position, tileIndex) => {
                        const hasLabel = position !== 'empty' && profile[profileIndex];

                        if (hasLabel) {
                            profileIndex++;
                        }

                        if (profileIndex >= profile.length) {
                            stopRendering = true;
                        }

                        return (
                            <div
                                key={tileIndex}
                                ref={(el) => (tilesRef.current[rowIndex * 3 + tileIndex] = el)}
                                className={`${styles.tile} ${styles[position]} ${styles[`pattern${(rowIndex * 3 + tileIndex) % 3 + 1}`]}`}
                            >
                                {hasLabel && (
                                    <span className={styles.label}>
                                        {profile[profileIndex - 1].label}
                                    </span>
                                )}
                            </div>
                        );
                    })}
                </div>
            );
        })}
    </div>
    );
};

export default TilesBricksUI;
