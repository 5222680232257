import React, { useContext, useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { MdOutlineError } from 'react-icons/md'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../Store/auth-context'
import styles from './EachStudentInterviewsData.module.css'

const EachStudentInterviewsData = (props) => {
    const { isOpen, data, onClose } = props
    const [allData, setAllData] = useState([])
    const authCtx = useContext(AuthContext)

    window.onclick = event => event.target.id === "mcmesid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    console.log(allData)


    const getApplicationsStatus = async () => {
        const getApplicationsStatusResponse = await fetch(BASE_URL_API + "/getApplicationsStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    applicationIdentifiers: JSON.parse(data)?.map(item => item?.applicationId).filter(Boolean)
                })
            })

        if (!getApplicationsStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getApplicationsStatusRespo = await getApplicationsStatusResponse.json()
            if (getApplicationsStatusRespo.status === "success") {
                setAllData(getApplicationsStatusRespo?.response)
            } else {
                if (getApplicationsStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllData([])
                }
            }
        }
    }

    useEffect(() => {
        getApplicationsStatus()
    }, [data])



    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer} id="mcmesid" >
                    <div className={styles.mainContent}>
                        <div className={styles.titleWrapper}>
                            Student Interviews Data
                        </div>

                        <div className={styles.allApplication}>

                            {allData?.map((item, index) => (
                                <div className={styles.eachApplicationWrapper}>
                                    {item?.map((itemMap, indexMap) => (
                                        <div key={indexMap} className={styles.eachStatus}>
                                            <div className={styles.applicationStatus}>
                                                {itemMap?.applicationStatus}
                                            </div>
                                            <div className={styles.trackWrapper}>
                                                <div className={indexMap !== 0 ? styles.lineForward : styles.lineForwardTrans}></div>
                                                <div className={itemMap?.applicationStatus === "REJECTED" ? styles.esDotRed : styles.esDot}>
                                                    {itemMap?.applicationStatus === "REJECTED" ?
                                                        <MdOutlineError size={20} color="aliceblue" />
                                                        :
                                                        <FaCheck size={20} color="green" />
                                                    }
                                                </div>
                                                <div className={indexMap === item?.length - 1 ? styles.lineForwardTrans : styles.lineForward}></div>
                                            </div>
                                            <div className={styles.remarkWrapper}>
                                                {itemMap?.applicationStatus === "INTERVIEW_SLOT_BOOKED" ?
                                                    <div className={styles.time}>
                                                        For {itemMap?.timeSlot}
                                                    </div>
                                                    :
                                                    <div className={styles.time}>
                                                        On {itemMap?.updatedOn}
                                                    </div>
                                                }
                                                {itemMap?.applicationStatus === "REJECTED" &&
                                                    <div className={styles.actionRemarks}>
                                                        {itemMap?.actionRemarks}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EachStudentInterviewsData