import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import EasyRead from '../Components/EasyRead';
import styles from './Home.module.css';

import { GrLinkNext } from "react-icons/gr";
import { BsFillLightningChargeFill } from "react-icons/bs";
import { IoCreate } from "react-icons/io5";
import { IoIosLink, IoMdFlash } from "react-icons/io";
import SlideInContent from '../Components/UX/SlideInContent';
import Counter from '../Components/UX/Counter';
import TileLayout from '../Components/UX/TileLayout';
import TilesBricksUI from '../Components/UX/TilesBricksUI';






const Home = () => {
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <div className={styles.fpWrapper}>
                    <div className={styles.logoWrapper}>
                        <img src="https://stylelink.s3.ap-south-1.amazonaws.com/fast-track-assets/fastTrack-white.png" />
                    </div>
                    <div className={styles.mainTitleWrapper}>
                        <h1>
                            Empower every talent of your institution
                        </h1>
                        <h1>
                            with Fast Track.
                        </h1>
                    </div>
                    <div className={styles.subTitleWrapper}>
                        <p>
                            Explore the leading integrated students management platform for academies, with tools for beauty professionals and anyone else whoever is a part of their beauty & salon industry.
                        </p>
                    </div>
                    <div className={styles.actionsWrapper}>
                        <div className={styles.actionsContainer}>
                            <div className={styles.loginBtn}>
                                <Link to={`/login`}>
                                    Sign up  <GrLinkNext fontSize={20} />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={styles.parallax}>
                    <div className={styles.parallaxContainer}>
                        <h2>
                            FAST TRACK
                        </h2>
                        <h2 className={styles.fullForm}>
                            (Facilitated And Streamlined Talent Recruitment And Coordination Kit)
                        </h2>
                        <p className={styles.definitionWrapper}>
                            is a comprehensive and advanced system designed to simplify and expedite your talent acquisition and placement activities. This system is equipped with features that allow for real-time tracking and updates, making it easier for you to manage the entire recruitment process of each students seamlessly.
                        </p>
                    </div>
                </div>
                <EasyRead />

                <div className={styles.howItWorksWrapper}>
                    <div className={styles.hiwTitleWrapper}>
                        <div className={styles.hiwTitleContainer}>
                            How FastTrack Works?
                        </div>

                    </div>
                    <div className={styles.hiwContentWrapper}>
                        <div className={styles.eachStepWrapper}>
                            <SlideInContent>
                                <div className={styles.eachStepTitleWrapper}>
                                    <div className={styles.estwIcon}>
                                        <IoCreate color="white" size={20} />
                                    </div>
                                    <div className={styles.estwTitle}>
                                        <p>
                                            Create Profile
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.estwTxt}>
                                    <p>
                                        Simply upload or create students profile on <span className={styles.boldIt}>FastTrack</span> with our auto-fetch / bulk upload features.
                                    </p>
                                </div>
                            </SlideInContent>
                        </div>

                        <div className={styles.eachStepWrapper}>
                            <SlideInContent>
                                <div className={styles.eachStepTitleWrapper}>
                                    <div className={styles.estwIcon}>
                                        <IoIosLink color="white" size={20} />
                                    </div>
                                    <div className={styles.estwTitle}>
                                        <p>
                                            Link Profile
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.estwTxt}>
                                    <p>
                                        Link students profile for <span className={styles.boldIt}>CA</span>mpus <span className={styles.boldIt}>P</span>lacements / <span className={styles.boldIt}>CO</span>ntinuous <span className={styles.boldIt}>P</span>lacements <span className={styles.boldIt}>P</span>rogram in a single click.
                                    </p>
                                </div>
                            </SlideInContent>
                        </div>

                        <div className={styles.eachStepWrapper}>
                            <SlideInContent>
                                <div className={styles.eachStepTitleWrapper}>
                                    <div className={styles.estwIcon}>
                                        <IoMdFlash color="white" size={20} />
                                    </div>
                                    <div className={styles.estwTitle}>
                                        <p>
                                            Super charge Placements
                                        </p>
                                    </div>
                                </div>
                                <div className={styles.estwTxt}>
                                    <p>
                                        With our <span className={styles.boldIt}>CAPP</span> & <span className={styles.boldIt}>COPP</span>, students get real-time job opportunities & feedbacks.
                                    </p>
                                </div>
                            </SlideInContent>
                        </div>
                    </div>

                </div>

                <div className={styles.trustedByWrapper}>
                    <SlideInContent>
                        <div className={styles.trustedByContainer}>
                            <div className={styles.tbcLeftWrapper}>
                                <div className={styles.trustedByTxt}>
                                    Trusted By
                                </div>
                                <div className={styles.studentCount}>
                                    <Counter startValue={0} endValue={6} time={500} direction="inc" />k+ students
                                </div>
                            </div>
                            <div className={styles.tbcRightWrapper}>
                                <p>
                                    Offering upto <span className={styles.bigHike}>3x</span> higher salary for camplus placements.
                                </p>
                            </div>
                        </div>
                    </SlideInContent>
                </div>





                <div className={styles.tileWrapper}>
                    <div className={styles.twTitle}>
                        <h3>
                            Profiles we recruit candidates for
                        </h3>
                    </div>
                    <TileLayout />
                </div>
                <div className={styles.tileWrapperSmall}>
                    <div className={styles.twsTitle}>
                        <h3>
                            Profiles we recruit candidates for
                        </h3>
                    </div>
                    <TilesBricksUI />
                </div>

                <div className={styles.upgradeWrapper}>
                    <div className={styles.uwTitle}>
                        <h3>Wanna upgrade your students' placements?</h3>
                    </div>
                    <div className={styles.contactUs}>
                        <button>Contact Us</button>
                    </div>
                </div>
                <div className={styles.reinventingWrapper}>
                    <div className={styles.reinventingContainer}>
                        <div className={styles.reinventTitle}>
                            <h4>
                                Reinventing the way placements done in beauty institutions.
                            </h4>
                        </div>
                        <div className={styles.startNowBtn}>
                            <Link to={`/login`}><button>Start Now</button></Link>
                        </div>
                    </div>

                </div>
                <div className={styles.copyrightWrapper}>
                    © All Rights Reserved | Stylelink
                </div>

            </div>
        </div>
    );
};

export default Home;
