export const sanitizeEmail = (email) => {
    // Regular expression for validating email format with TLDs
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Regular expression for allowed characters in an email
    const allowedCharsRegex = /^[a-zA-Z0-9._%+-@]+$/;

    // Regular expression to check for more than two consecutive dots
    const consecutiveDotsRegex = /\.{3,}/;

    let response = {
        isValid: false,
        sanitizedEmail: '',
        message: ''
    };

    if (typeof email !== 'string') {
        response.message = 'Invalid input type: Email must be a string';
        response.sanitizedEmail = email;
        return response;
    }

    email = email.trim();

    // Remove characters not allowed in email addresses
    email = email.replace(/[^a-zA-Z0-9._%+-@]/g, '');

     // Check if the sanitized email matches the regex
     if (emailRegex.test(email) && allowedCharsRegex.test(email)) {
        // Check length constraints
        if (email.length >= 4 && email.length <= 300) {
            response.isValid = true;
            response.sanitizedEmail = email;
            response.message = 'Email is valid & sanitized successfully';
        } else {
            response.message = 'Email length should be between 4 and 300 characters';
        }
    } else {
        response.message = 'Invalid e-mail format';
    }

    // Check for multiple @ symbols
    if (email.split('@').length > 2) {
        response.message = 'Email should contain only one @ symbol';
        response.sanitizedEmail = email;
        return response;
    }

    // Check for more than two consecutive dots
    if (consecutiveDotsRegex.test(email)) {
        response.message = `Email shouldn't contain more than two consecutive dots`;
        response.sanitizedEmail = email;
        return response;
    }

   

    response.sanitizedEmail = email;
    return response;
}
