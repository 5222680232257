import React, { useContext, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import LeftMenu from '../Components/Menu/LeftMenu';
import UpperHeader from '../Components/Menu/UpperHeader';
import NetworkStatus from '../Components/UX/NetworkStatus';
import ForgotPassword from '../Pages/Authentication/ForgotPassword';
import Login from '../Pages/Authentication/Login';
import AllDrives from '../Pages/Drives/AllDrives';
import EachDrive from '../Pages/Drives/EachDrive';
import Home from '../Pages/Home';
import MainHome from '../Pages/LandingPages/MainHome';
import AuthContext from '../Store/auth-context';
import styles from './MainNavigator.module.css'

const MainNavigator = () => {
    const authCtx = useContext(AuthContext);
    const [isMenuOpen, setIsMenuOpen] = useState(true)
    const menuToggler = () => setIsMenuOpen(!isMenuOpen)

    const accessibilityData = JSON.parse(authCtx?.loginData)?.accessibilityData

    console.log(authCtx)

    if (!authCtx.isLoggedIn) {
        return (
            <div className={styles.mainWrapper}>
                <NetworkStatus />
                <Routes>
                    <Route path="*" element={<Home />}></Route>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                </Routes>
            </div>
        )
    } else {
        return (
            <div className={styles.mainWrapper}>
                <NetworkStatus />
                <div className={isMenuOpen !== true ? styles.open : styles.close}>
                    <LeftMenu isOpen={isMenuOpen} toggler={menuToggler} />
                </div>
                <div className={styles.rightCol}>
                    <div className={styles.upperHeaderWrapper}>
                        <UpperHeader />
                    </div>
                    <Routes>
                        <Route path="*" element={<MainHome />}></Route>
                        <Route path="/" element={<MainHome />}></Route>
                        <Route path="/drives" element={<AllDrives />}></Route>
                        <Route path="/drive/:driveIdentifier" element={<EachDrive />}></Route>
                        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
                    </Routes>
                </div>
            </div>
        )
    }


}

export default MainNavigator