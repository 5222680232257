import React from 'react';
import SlideInContent from './SlideInContent';
import styles from './TileLayout.module.css';

const TileLayout = () => {
    return (
        <div className={styles.mainWrapper}>
            <SlideInContent>
                <div className={styles.container}>
                    <div className={styles.tile1}>Beautician</div>
                    <div className={styles.tile2}>Pedicurist</div>
                    <div className={styles.tile3}>Manager</div>
                    <div className={styles.tile4}>Makeup Artist</div>
                    <div className={styles.tile5}>Eye-lash Extension Expert</div>
                    <div className={styles.tile6}>Helper</div>
                    <div className={styles.tile7}>Massage Therapist</div>
                    <div className={styles.tile8}>Receptionist</div>
                    <div className={styles.tile9}>Beauty Advisor / Consultant / Trainer</div>
                    <div className={styles.tile10}>Trainers</div>
                    <div className={styles.tile11}>Female Hairdresser / Hairstylist</div>
                    <div className={styles.tile12}>Gents / Unisex Hairdresser / Hairstylist</div>
                    <div className={styles.tile13}>Nail Art / Technician</div>
                </div>
            </SlideInContent>
        </div>
    );
};

export default TileLayout;
