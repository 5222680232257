import React, { useContext, useEffect, useState } from 'react'
import { FaSearch } from 'react-icons/fa'
import { MdCancel } from 'react-icons/md'
import ActiveStoreContext from '../../Store/ActiveStoreContext'
import AuthContext from '../../Store/auth-context'
import styles from './ActiveStoreSelector.module.css'

const ActiveStoreSelector = (props) => {
    const authCtx = useContext(AuthContext)
    const asCtx = useContext(ActiveStoreContext)
    const { isOpen, onClose, onSuccess } = props
    const storesData = JSON.parse(authCtx?.loginData)?.storesData
    const [isSearchBarOpen, setIsSearchBarOpen] = useState(false)
    const [filteredData, setFilteredData] = useState(JSON.parse(authCtx?.loginData)?.storesData)
    const [searchKey, setSearchKey] = useState('')

    console.log("this is asCtx:  ", asCtx)

    window.onclick = event => event.target.id === "astsmcid" ? onClose() : null
    document.addEventListener('keydown', function (event) {
        if (event.key === 'Escape') {
            onClose()
        }
    });

    const filterData = (data, searchKey) => {
        setFilteredData(data.filter(item =>
            Object.values(item).some(value =>
                value?.toString().toLowerCase().includes(searchKey.toLowerCase())
            )
        ))
    }

    useEffect(() => {
        filterData(storesData, searchKey)
    }, [searchKey])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer} id="astsmcid" >
                <div className={styles.mainContent}>
                    {isSearchBarOpen === false ?
                        <div className={styles.topHeader} onClick={() => setIsSearchBarOpen(true)} >
                            Select Active Store <FaSearch size={16} />
                        </div>
                        :
                        <div className={styles.topHeader} onClick={() => setIsSearchBarOpen(false)} >
                            <input autoFocus value={searchKey}
                                onChange={(e) => setSearchKey(e.target.value)}
                                placeholder="Search for anything..."
                            /><MdCancel size={24} />
                        </div>
                    }
                    <div className={styles.storesWrapper}>
                        {filteredData?.map((item, index) => (
                            <div className={styles.eachStoreWrapper} key={index} onClick={() => onSuccess(item)}>
                                <div className={styles.storeName}>{item?.storeName}</div>
                                <div className={styles.storeAddress}>{item?.storeAddress}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ActiveStoreSelector