import React, { useEffect, useRef, useState } from 'react';
import styles from './EasyRead.module.css';

const EasyRead = () => {
    const [fixed, setFixed] = useState(false);
    const [highlightedWordIndex, setHighlightedWordIndex] = useState(-1);
    const [placeholderHeight, setPlaceholderHeight] = useState(0);
    const containerRef = useRef(null);
    const placeholderRef = useRef(null);
    const initialTopOffset = useRef(0); // To store the initial offset top of the container

    const text = "With FastTrack you can track placements of every students of your institution, see their resumes, scheduled interviews, demos, trials, hiring places, and it all happen at one place while securely storing all your students informations. It's that simple. :)";
    const words = text.split(' ');

    useEffect(() => {
        if (containerRef.current) {
            initialTopOffset.current = containerRef.current.getBoundingClientRect().top + window.scrollY;
            setPlaceholderHeight(containerRef.current.getBoundingClientRect().height);
        }

        const handleScroll = () => {
            if (!containerRef.current) return;

            const scrollPosition = window.scrollY;

            if (scrollPosition >= initialTopOffset.current && scrollPosition < (initialTopOffset.current + placeholderHeight)) {
                setFixed(true);
                const scrolledAmount = scrollPosition - initialTopOffset.current;
                const scrollPercentage = scrolledAmount / placeholderHeight;
                const wordsToHighlight = Math.floor(scrollPercentage * words.length);
                setHighlightedWordIndex(wordsToHighlight - 1);
            } else {
                setFixed(false);
                setHighlightedWordIndex(-1); // Reset highlighted words when not fixed
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [placeholderHeight]);

    return (
        <>
            <div className={`${styles.container} ${fixed ? styles.fixed : ''}`} ref={containerRef}>
                <div className={styles.textWrapper}>
                    <p className={styles.text}>
                        {words.map((word, index) => (
                            <span
                                key={index}
                                className={index <= highlightedWordIndex ? styles.highlighted : ''}
                            >
                                {word}{' '}
                            </span>
                        ))}
                    </p>
                </div>
            </div>
            {fixed === false &&
                <div ref={placeholderRef} className={styles.placeholder}>
                    <div className={styles.textWrapper}>
                        <p className={styles.text}>
                            {words.map((word, index) => (
                                <span
                                    key={index}
                                    className={styles.highlighted}
                                >
                                    {word}{' '}
                                </span>
                            ))}
                        </p>
                    </div>
                </div>
            }
        </>
    );
};

export default EasyRead;
