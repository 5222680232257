import React, { useEffect, useState } from 'react';

const Counter = ({ startValue, endValue, time, direction }) => {
    const [count, setCount] = useState(startValue);

    useEffect(() => {
        const isIncrement = direction === 'inc';
        const difference = Math.abs(endValue - startValue);
        const stepTime = time / difference;

        const updateCount = () => {
            setCount((prevCount) => {
                if (isIncrement && prevCount < endValue) {
                    return prevCount + 1;
                } else if (!isIncrement && prevCount > endValue) {
                    return prevCount - 1;
                }
                return prevCount;
            });
        };

        const interval = setInterval(updateCount, stepTime);

        return () => clearInterval(interval);
    }, [startValue, endValue, time, direction]);

    return <span>{count}</span>;
};

export default Counter;
