import React, { useContext } from 'react'
import AuthContext from '../../Store/auth-context'
import styles from './MainHome.module.css'

const MainHome = () => {
    const authCtx = useContext(AuthContext)
    return (
        <div>

            <button onClick={() => authCtx.logout()} >Logout</button>
        </div>
    )
}

export default MainHome